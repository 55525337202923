/* @flow */

import type { AdventCalendar } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: AdventCalendar }
  | { state: "ERROR", error: string };

export type AdventCalendarResponse = Response<typeof ADVENT_CALENDAR_RESPONSE, ?AdventCalendar>;

export type AdventCalendarRequest = {
  tag: typeof ADVENT_CALENDAR_REQUEST,
  testDate?: string,
  password?: string,
};

export const adventCalendarActive = (date: Date, dateStr: string): boolean => {
  const timeZone = dateStr.slice(-6);
  const MIN_DATE: Date = new Date(`2025-04-16T00:00:00${timeZone}`);
  const MAX_DATE: Date = new Date(`2025-04-21T23:59:59${timeZone}`);

  return date >= MIN_DATE && date <= MAX_DATE;
};

export const ADVENT_CALENDAR_RESPONSE: "response/advent-calendar" = "response/advent-calendar";
export const ADVENT_CALENDAR_REQUEST: "request/advent-calendar" = "request/advent-calendar";

export const AdventCalendarModel: Model<
  Data,
  { testDate: ?string, password: ?string },
  AdventCalendarRequest | AdventCalendarResponse
> = {
  id: "advent-calendar",
  init: ({ testDate, password }: { testDate: ?string, password: ?string }) =>
    updateData(
      { state: "LOADING" },
      { tag: ADVENT_CALENDAR_REQUEST, testDate, password }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case ADVENT_CALENDAR_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
